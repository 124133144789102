<template>
  <div class="pa-0 ma-0">
    <v-btn :block="block" text depressed class="px-2 px-sm-3 text-capitalize" @click="open()">
      <v-icon color="accent" class="mr-2">mdi-table-plus</v-icon>Bulk Add Courses
    </v-btn>
    <v-dialog v-model="dialogOpen" :fullscreen="store.app.onMobile" max-width="750px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px" :loading="loading" loader-height="5"
              class="overflow-y-auto">
        <v-card-title class="pt-6 text-h5 font-weight-bold accent--text">
          Add Courses to Timetable
          <v-spacer/>
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-6">
          <v-row>
            <v-col cols="12" class="px-4 pt-1 col-md-5 flex-col justify-center">

              <p class="my-2 text--secondary">Enter a comma separated list of courses below. (max 10)</p>
              <v-textarea v-model="courses" :disabled="loading" hide-details flat outlined no-resize height="150"
                          class="grow-0"
                          placeholder="CSCA08H3, LIN101H1, ..." />
              <div class="mt-2 d-flex justify-space-between">
                <v-select v-model="semesterChosen" class="mr-4" label="Semester" color="accent" :items="['Fall', 'Winter', 'Summer']" />
                <v-btn @click="addCourses()" :disabled="loading" text color="accent" class="mt-3">Search</v-btn>
              </div>

              <v-spacer />
              <v-btn :disabled="!hasCoursesChosen" @click="handleAdd()" depressed color="accent">
                Add Courses
              </v-btn>
            </v-col>

            <v-col cols="1" class="text-center px-0 mx-0 d-none d-md-block" style="max-width: 20px">
              <v-divider vertical/>
            </v-col>

            <v-col class="px-0 text-center overflow-y-auto">
              <v-tabs v-model="tab" grow color="accent">
                <v-tab v-for="tab in tabs" :key="tab" class="text-capitalize">{{ tab }}</v-tab>

                <v-tab-item
                  :key="index"
                  v-for="(data, index) in [['Fall', fallCourses], ['Winter', winterCourses], ['Summer', summerCourses]]"
                >
                  <LoadCartSemesterCourses :semester="data[0]" :courses="data[1]" @updated="handleUpdate" />
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import Bugsnag from '@bugsnag/js'
import LoadCartSemesterCourses from '@/components/timetable/load-cart/LoadCartSemesterCourses.vue'
import { sectionsOverlap } from '@/utils/CSP/constraints'
import { sanitizeCourseData } from '@/utils/shared/courses'

export default {
  name: 'LoadTimetable',
  props: {
    openModal: { type: Boolean, default: false },
    block: { type: Boolean, default: false },

    /** Check for conflicts against these */
    existingFallCourses: {},
    existingWinterCourses: {},
    existingSummerCourses: {}
  },
  components: {
    LoadCartSemesterCourses
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    dialogOpen: false,
    courses: null,
    tab: null,
    tabs: ['Fall', 'Winter', 'Summer'],

    loading: false,
    semesterChosen: null,

    fallCourses: [],
    winterCourses: [],
    summerCourses: []
  }),
  watch: {
    openModal: function (newValue) {
      if (newValue) this.dialogOpen = true
    }
  },
  computed: {
    hasCoursesChosen () {
      return (
        [
          this.fallCourses,
          this.winterCourses,
          this.summerCourses
        ].reduce((prev, curr) => prev || curr.length > 0, false)
      )
    }
  },
  methods: {
    open () { this.dialogOpen = true; this.$gtag.event('tt_open_bulk_add_courses', { value: 1 }) },
    close () {
      this.reset()
      this.dialogOpen = false
    },
    reset () {
      this.fallCourses = []
      this.winterCourses = []
      this.summerCourses = []
      this.courses = null
      this.tab = null
    },
    handleUpdate ({ code, semester, type, section }) {
      let toUpdate
      switch (semester) {
        case 'F': toUpdate = this.fallCourses; break
        case 'W': toUpdate = this.winterCourses; break
        case 'S': toUpdate = this.summerCourses; break
      }

      const course = toUpdate.find(c => c.code === code)

      // Not possible for it to fail. If it did,
      // something has gone terribly wrong...
      course.chosen[type] = section
    },
    async addCourses () {
      if (!this.semesterChosen) {
        this.$toast.error('Must choose a semester.')
        return
      }

      if (!this.courses) {
        return
      }

      this.loading = true

      const request = { courses: {}, options: null }
      request.courses[this.semesterChosen] = this.courses.split(',')
        .map(course => course.trim().toLowerCase())
        .filter(course => course !== '')

      const res = await fetch('/rest/v1/courses/bulk', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      })

      if (res.status !== 200) {
        Bugsnag.notify(res)
        this.$toast.error('Failed to get courses. Please try again later.')

        this.doneLoading()
        return
      }

      const data = await res.json()
      this.addToSemester(this.semesterChosen, data)
      this.$gtag.event('tt_searched_for_bulk_courses', { value: 1 })

      this.doneLoading()
    },
    addToSemester (semester, courses) {
      let addTo, checkAgainst
      switch (semester) {
        case 'Fall': addTo = this.fallCourses; checkAgainst = this.existingFallCourses; break
        case 'Winter': addTo = this.winterCourses; checkAgainst = this.existingWinterCourses; break
        case 'Summer': addTo = this.summerCourses; checkAgainst = this.existingSummerCourses; break
      }

      // Make sure we don't add duplicates!
      courses.forEach(course => {
        if (addTo.findIndex(c => c._id === course._id) === -1 && !checkAgainst[course.code]) {
          sanitizeCourseData(course)

          // Want prefix such as "F22 - ", "W23 - ", etc.
          const sectionPrefix = course.sem + course.year.slice(2) + ' - '

          course.semester = course.sem

          // Add prefix to each section entry, hacky
          course.lec.forEach(sec => { sec.section = sectionPrefix + sec.section })
          course.tut.forEach(sec => { sec.section = sectionPrefix + sec.section })

          course.chosen = this.maybeFindNonConflictingSections(course, checkAgainst)

          addTo.push(course)
        }
      })

      this.tab = this.tabs.indexOf(semester)
    },
    handleAdd () {
      this.$emit('selected', {
        fall: this.fallCourses,
        winter: this.winterCourses,
        summer: this.summerCourses
      })

      this.$gtag.event('tt_added_bulk_courses', { value: 1 })
      this.reset()
      this.close()
    },
    doneLoading () {
      setTimeout(() => { this.loading = false }, 250)
    },
    /** Try and find non-conflicting lectures + tutorials for given course */
    maybeFindNonConflictingSections (toAdd, checkAgainst) {
      const result = {
        lecture: toAdd.lec[0],
        tutorial: toAdd.tut[0]
      }

      for (const section of toAdd.lec) {
        if (this.hasNoConflicts(section, checkAgainst)) {
          result.lecture = section
          break
        }
      }

      for (const section of toAdd.tut) {
        if (this.hasNoConflicts(section, checkAgainst)) {
          result.tutorial = section
          break
        }
      }
      // TODO: Repeat for practicals?

      return result
    },
    hasNoConflicts (section, checkAgainst) {
      for (const cID in checkAgainst) {
        const course = checkAgainst[cID]

        if (sectionsOverlap(section, course.lecture)) return false
        if (sectionsOverlap(section, course.tutorial)) return false
      }
      return true
    }
  }
}
</script>

<style scoped>
  >>>.v-input.v-textarea>.v-input__control>.v-input__slot:before {
    border: none;
  }

</style>
